@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
.form-login-logo {
  max-width: 300px;
}

.form-login {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  width: 100%;
}

.bg-full {
  height:  100vh;
}

.form-login input,
.form-login input:-webkit-autofill,
.form-login input:-webkit-autofill:hover,
.form-login input:-webkit-autofill:focus,
.form-login select,
.form-login select:-webkit-autofill,
.form-login select:-webkit-autofill:hover,
.form-login select:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #aac000;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form-login input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form-login input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

.form-login label {
  color: white;
}

.form-login h5 {
  color: white;
}

.form-login-loja label {
  color: black;
}

.form-login-loja h5 {
  color: black;
}

.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv
  .sidenav---sidenav-nav---3tvij
  .sidenav---sidenav-navitem---uwIJ-:hover
  > .sidenav---sidenav-subnav---1EN61 {
  display: none;
}

.sidenav---sidenav---_2tBP {
  max-width: 64px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 500;
}

.sidenav---navicon---3gCRo {
  width: 100%;
}

.sidenav---sidenav---_2tBP {
  background-color: rgba(0, 0, 0, 0.9);
  transition: none;
}

.sidenav---sidenav-subnav---1EN61 {
  border-radius: 4px;
  border: none;
}

.sidenav---sidenav-navitem---uwIJ-:hover {
  background-color: rgba(0, 0, 0, 1);
  padding-left: 10px;
  transition: padding-left 0.2s;
}

.sidenav---navtext---1AE_f:hover {
  padding-left: 10px;
  transition: padding-left 0.2s;
}

.nav-font,
.nav-font:visited,
.nav-font:hover,
.nav-font:active,
.nav-font:focus,
.nav-font:active:hover {
  font: 16px 'Roboto', sans-serif;
  font-weight: 500;
  text-decoration: none !important;
  color: white;
}

.nav-toggle,
.sidenav---sidenav-toggle---1KRjR {
  margin: 10px 0px 10px 0px;
}

#logo-nav {
  display: flex;
  margin: 10px 0px 10px 0px;
  max-width: 110px;
  width: 100%;
}

#perfil {
  display: flex;
  flex-direction: column;
  cursor: default;
  height: auto;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
}

.nome-usuario {
  max-width: 100%;
  text-align: center;
  margin-bottom: 5px;
}
.nav-titulo {
  font-size: 16px;
  margin-top: 10px;
}

.nav-sub-titulo {
  font-size: 13px;
  background: none;
  border: none;
  color: white;
  text-align: start;
  margin-top: 10px;
}

.nav-sub-titulo:hover {
  font-size: 14px;
}

/* width */
nav::-webkit-scrollbar {
  width: 3px;
}

/* Track */
nav::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
nav::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
nav::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.sidenav---navtext---1AE_f,
.sidenav---navitem---9uL5T {
  width: 100% !important;
  height: 100% !important;
}

.link-unstyled,
.link-unstyled:visited,
.link-unstyled:hover,
.link-unstyled:active,
.link-unstyled:focus,
.link-unstyled:active:hover {
  font-style: inherit;
  color: inherit;
  background-color: inherit;
  font-size: inherit;
  text-decoration: inherit;
  font-feature-settings: inherit;
  font-variant: inherit;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  border-radius: inherit;
  border: inherit;
  outline: inherit;
  box-shadow: inherit;
  padding: inherit;
  vertical-align: inherit;
}

.main-painel {
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 70px;
  background-color: #eeeeee;
  overflow-y: auto;
}

.form-logo {
  max-width: 300px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
}

.form input,
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form select,
.form select:-webkit-autofill,
.form select:-webkit-autofill:hover,
.form select:-webkit-autofill:focus,
.form textarea,
.form textarea:-webkit-autofill,
.form textarea:-webkit-autofill:hover,
.form textarea:-webkit-autofill:focus {
  background: white !important;
  border: 0;
  border-radius: 4px;
  height: 44px;
  padding: 0 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #aac000;
  -webkit-text-fill-color: black;
  -webkit-text-size-adjust: 1rem !important;
  font-size: 1rem !important;
  color: black;
}

.form input[type='file'] {
  color: black;
  background-color: white;
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
}

.form input[type='file']:before {
  display: none;
  font-style: normal;
  font-weight: normal;
}

input::-webkit-input-placeholder {
  color: green !important;
}

input:-ms-input-placeholder {
  color: green !important;
}

input::placeholder {
  color: green !important;
}

.card-dash {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #aac000;
  align-items: center;
  width: 100%;
}

.card-dash-titulo {
  color: white;
  font-size: 18px;
  width: 70%;
  font-weight: 500;
  overflow-x: hidden;
}

.dash-table-titulo span {
  overflow-x: hidden;
}

.card-dash-subtitulo {
  color: white;
  font-size: 30px;
  text-align: end;
  width: 30%;
}
.card-dash-footer {
  width: 100%;
  background-color: white;
  height: 2px;
}

.dash-table {
  border-radius: 0px 0px 4px 4px;
}

.dash-col {
  border: none;
}

.dash-table-titulo {
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  color: #171717;
  background-color: #eeeeee;
  border-radius: 4px 4px 0px 0px;
  display: flex;
}

.dash-table-body {
  font-size: 14px;
}

.dash-table-header {
  font-size: 14px;
}

.tr-gray {
  background-color: #777777;
}

h4 {
  color: white;
}

.lojas .loja-checkbox input {
  margin-bottom: 0;
  margin-right: 10px;
}


.loja-checkbox {
  display: flex;
  justify-content: left;
  align-items: center;
}

.loja-checkbox label {
  font-size: 14px;
}

.aviso {
  font-size: 12px;
  color: black;
}

label {
  font-size: 12px;
  color: black;
}

.react-select__value-container {
  height: 50px;
}

.react-select__control.css-yk16xz-control {
  border-color: #aac000;
}

.lista-item {
  color: black;
  padding: 5px;
  margin: 0;
}

.lista {
  list-style: none;
  text-align: center;
  width: 100%;
}

.btn-adicionar {
  padding: 10px 15px 10px 15px;
  height: 44px;
  background-color: #aac000;
  font-weight: bold;
  color: black;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-remover {
  height: 44px;
  font-weight: bold;
  color: black;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  margin-left: 5px;
  background-color: black;
  color: #aac000;
}

.btn-remover:hover {
  background-color: black;
}

#export-menu {
  display: block;
  height: auto;
  position: absolute;
  padding: 10px 0px 0px 0px;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  color: black;
  z-index: 10;
  top: 1;
  right: 0;
}

#export-menu button {
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-align: center;
  margin-bottom: 10px;
}

#export-menu button:hover {
  background-color: #cccccc;
}

.card-detalhe {
  width: 100%;
  height: 190px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
}

.card-infos {
  display: flex;
  flex-direction: column;
  height: 130px;
  margin: auto;
  margin-top: 10px;
}

.card-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-links a,
.card-links button {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.card-links a {
  color: #aac000;
}

.card-links a:hover {
  color: #aac000;
}

.card-imagem {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-imagem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-imagem video {
  width: 100%;
}

.card-midia {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-midia-titulo {
  font-weight: bold;
}

.h-container {
  height: 85.77% !important;
}

.footer {
  height: auto;
  background-color: #aac000;
  font-size: 12px;
  width: 100%;
  color: white;
}
.footer-fixed {
  height: auto;
  background-color: #aac000;
  font-size: 12px;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: white;
}

.header-home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  background-color: #777777;
  font-size: 14px;
  width: 100%;
}

.header-home-auto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  height: auto;
  background-color: #777777;
  font-size: 14px;
  width: 100%;
}

.header-home span,
.header-home a,
.header-home-auto span,
.header-home-auto a {
  color: white;
}

.header-home a:hover,
.header-home-auto a:hover {
  text-decoration: none;
  color: white;
}

.nav-sair {
  background: none;
  border: none;
  color: white;
  text-align: start;
}
.nav-link:hover {
  padding-top: 3px;
}

.nav-link:hover {
  padding-top: 3px;
}

.nav-link {
  color: white !important;
}
.navbar .navbar-toggler-icon {
  color: white;
}

.nav-menu {
  color: white;
}

.nav-logo {
  max-width: 160px;
}
.navbar-toggler {
  width: 100%;
}

.banner-home {
  margin: 50px;
  max-width: 100%;
}

.h-container {
  height: 87.77% !important;
}

@media only screen and (max-width: 844px) {
  .h-container {
    height: 79.69% !important;
  }
}

form.form.fundo-claro label {
  color: black !important;
}

form.form.loja {
  max-width: 100%;
}

.teste {
  color: black;
}

:root {
  --cor-primaria: #aac000;
}

.card-premio-nome {
  height: 50px;
  overflow: hidden;
}

.input-busca {
  border: none;
  color: black;
  width: 150px;
}

.btn-pesquisa {
  width: 100px;
  height: 50px;
  border: none;
  border-radius: 6px;
  background-color: #aac000;
  color: #050;
  font-weight: bold;
  margin: 0 0 0 5px;
  padding: 0 8px;
}

.sticky{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  padding: 10px 0px;
  z-index: 999;
}

#container-busca-geral {
  display: flex;
  flex-direction: row;
}

@media (max-width: 767px) {
  .sticky-search{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding: 10px 0 40px 0;
    z-index: 999;
    background-color: #fff;
  }
}

.input-busca::-webkit-input-placeholder {
  color: grey !important;
  font-size: 12.5px;
}

.input-busca:-ms-input-placeholder {
  color: grey !important;
  font-size: 12.5px;
}

.input-busca::placeholder {
  color: grey !important;
  font-size: 12.5px;
}

@media screen and (min-width: 576px) {
  .input-busca {
    border: none;
    color: black;
    width: 250px;
  }

  .input-busca::-webkit-input-placeholder {
    color: grey !important;
    font-size: 14px;
  }

  .input-busca:-ms-input-placeholder {
    color: grey !important;
    font-size: 14px;
  }

  .input-busca::placeholder {
    color: grey !important;
    font-size: 14px;
  }
}

.btn-resgatar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #aac000;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-resgatar-red {
  width: 150px;
  padding: 5px;
  height: 44px;
  background-color: red;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.btn-resgatar:hover {
  color: #aac000;
  border: 1px solid #aac000;
  background-color: white;
  white-space: nowrap;
}

.container-busca-geral {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-busca {
  width: 60%;
  padding: 0 5px;
}

.container-busca-dois {
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  margin-top: 20px;
}

.container-busca h5 {
  align-self: flex-start;
}

.container-busca-dois h5 {
  margin-bottom: 15px;
  padding-left: 8px;
}

.container-secaoDropdown-dois {
  width: 100%;
}

.secaoDropdown {
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 50px;
  margin-right: 0;
}

.secaoDropdown-dois {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-right: 0;
  padding: 0;
}

.input-busca {
  border: none;
  border-radius: 6px;
  background: #f1f1f1;
  width: 79%;
  margin-left: 0;
}

.input-busca::-webkit-input-placeholder {
  font-size: 16px;
}

.input-busca:-ms-input-placeholder {
  font-size: 16px;
}

.input-busca::placeholder {
  font-size: 16px;
}

.dropbtn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #f1f1f1;
  color: gray;
  text-align: center;
  font-weight: bold;
  width: 100%;
  height: 50px;
  padding: 5px 0px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.dropbtn i {
  margin: 0 10px 0 -2px;
  overflow: hidden;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #f1f1f1;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  max-height: 300px;
  width: 100%;
  min-width: 30%;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}

.dropdown-content::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background: #dad7d7;
  border-radius: 8px;
}

.dropdown-content button {
  color: black;
  padding: 12px 16px;
  width: 100%;
  border: none;
  text-decoration: none;
  display: block;
}

.dropdown-content button:hover {
  background-color: #ddd;
  font-weight: bold;
  text-decoration: none;
  color: gray;
}

.show {
  display: block;
}

#sidebar {
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 15px 0 0 10px;
  border-radius: 8px;
  height: 380px;
}

#sidebar .conteudo-subcategoria {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

#sidebar .conteudo-subcategoria::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}

#sidebar .conteudo-subcategoria::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4;
}

#sidebar .conteudo-subcategoria::-webkit-scrollbar-thumb {
  background: #dad7d7;
  border-radius: 8px;
}

.conteudo-subcategoria button {
  width: 100%;
  border: none;
  padding: 5px 0;
  text-align: left;
  background-color: none;
}

.conteudo-subcategoria button:hover {
  font-weight: bold;
  color: gray;
  background-color: #ddd;
}

/* Sidebar mobile */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: #eee;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}

.container-sidenav {
  display: none;
}

.container-sidenav span {
  border: 1px solid #aac000;
  border-radius: 6px;
  color: #aac000;
  margin: 0 20px 0 0;
  padding: 5px 8px;
  align-self: flex-end;
}

.sidenav h6 {
  font-size: 24px;
  font-weight: bold;
  color: #818181;
  padding: 8px 8px 8px 25px;
}

.sidenav button {
  padding: 8px 8px 8px 25px;
  text-decoration: none;
  font-size: 22px;
  text-align: start;
  background-color: #eee;
  color: #818181;
  border: none;
  display: block;
  transition: 0.3s;
}

.sidenav button:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  color: gray;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left .5s;
  padding: 20px;
}

.conteudo-subcategoria-mobile {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.conteudo-subcategoria-mobile::-webkit-scrollbar-track {
  background-color: #F4F4F4;
}

.conteudo-subcategoria-mobile::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4;
}

.conteudo-subcategoria-mobile::-webkit-scrollbar-thumb {
  background: #aac000;
  border-radius: 8px;
}

.hr-loja {
  display: none;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav button {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  #container-busca-geral {
    display: flex;
    flex-direction: column;
  }

  .container-busca {
    width: 100%;
    padding: 0 5%;
    height: 100%;
    margin: 0 0 -75px 0;
  }

  .container-busca-dois {
    width: 100%;
    padding: 0 5%;
    height: 140px;
    margin: 0 0 -30px 0;
  }

  .container-busca h5 {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .container-busca-dois h5 {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  .container-secaoDropdown {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .container-secaoDropdown-dois {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0;
    padding: 0 0 75px 0;
    height: 5px;
  }

  .secaoDropdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 180px;
  }

  .secaoDropdown-dois {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .hr-loja {
    display: flex;
    margin: 0 0 10px 0;
  }

  .hr-loja-desktop {
    display: none;
  }

  .input-busca {
    display: block;
    width: 100%;
    margin: 0 0 10px 0;
    height: 50px;
  }

  .input-busca::-webkit-input-placeholder {
    text-align: center
  }

  .input-busca:-ms-input-placeholder {
    text-align: center
  }

  .input-busca::placeholder {
    text-align: center
  }

  .dropdown {
    padding: 0;
  }

  .dropbtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border: none;
    margin: 20px 0 5px 0;
    overflow: hidden;
  }

  .dropbtn i {
    margin: 0 0 0 5px;
  }

  .dropdown-content {
    width: 100%;
  }

  .btn-pesquisa {
    width: 100%;
    height: 50px;
    margin: 0;
  }

  #sidebar {
    display: none;
  }

  .container-sidenav {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: flex-end;
  }
}

.categorias-lista {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  width: 100%;
  padding: 8px;
  background-color: #f1f1f1;
  border-radius: 6px;
  height: 300px;
  overflow-y: auto;
}

.categoria-btn {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  border: none;
  border-radius: 4px;
  background: none;
  color: #333;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  min-height: 44px;
}

.categoria-btn:hover {
  background-color: #e9ecef;
}

.categoria-btn.active {
  background-color: #aac000;
  color: white;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .categorias-lista {
    padding: 4px;
    height: 200px;
  }

  .categoria-btn {
    padding: 8px 12px;
    font-size: 13px;
    min-height: 40px;
  }
}


.premio-titulo {
  font-size: 30px;
  font-weight: 500;
}

.premio-subtitulo {
  font-size: 18px;
  font-weight: 400;
}

.premio-descricao {
  font-size: 12px;
}

.premio-status {
  font-size: 12px;
  color: #fff;
  line-height: 18px;
  background: #6dbe14;
  text-transform: uppercase;
  padding: 3px 15px;
  font-weight: bold;
  border-radius: 999px;
}

.premio-status-carregando {
  font-size: 12px;
  color: #fff;
  line-height: 18px;
  background: #777777;
  text-transform: uppercase;
  padding: 3px 15px;
  font-weight: bold;
  border-radius: 999px;
}

.premio-status-indisponivel {
  font-size: 12px;
  color: #fff;
  line-height: 18px;
  background: red;
  text-transform: uppercase;
  padding: 3px 15px;
  font-weight: bold;
  border-radius: 999px;
}

.btn-add-qtde,
.btn-sub-qtde {
  width: 30px;
  height: 30px;
  background-color: #aac000;
  font-weight: bold;
  color: black;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.detalhe ol li {
  background-color: #777777;
}

.detalhe a.carousel-control-next span {
  background-color: #777777;
}

.detalhe a.carousel-control-prev span {
  background-color: #777777;
}

.btn-atualizar {
  padding: 5px;
  width: 200px;
  height: 44px;
  background-color: #aac000;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-atualizar:hover {
  color: #aac000;
  border: 1px solid #aac000;
  background-color: white;
}

.tabela-header-claro {
  text-align: center !important;
}

.tabela-body-claro {
  text-align: center !important;
}

.pagination.react-bootstrap-table-page-btns-ul li.active a {
  color: white !important;
  background-color: #aac000 !important;
  border: none;
}

.pagination.react-bootstrap-table-page-btns-ul li a {
  color: #aac000 !important;
  background-color: white;
  border: none;
}

.dropdown-item a {
  color: black;
}

#pageDropDown {
  color: white !important;
  background-color: #aac000 !important;
  border: none;
  box-shadow: none;
}

div.col-md-6.col-xs-6.col-sm-6.col-lg-6,
.react-bootstrap-table-pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.page-link:focus {
  box-shadow: none !important;
}

.sort-asc,
.sort-desc {
  color: black;
}

.react-bootstrap-table {
  overflow-y: auto;
}

.contato-mensagem {
  min-height: 100px;
}

.btn-enviar {
  width: 130px;
  padding: 5px;
  height: 44px;
  background-color: #aac000;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
.btn-enviar:hover {
  color: #aac000;
  border: 1px solid #aac000;
  background-color: white;
}

.modal-body {
  max-height: 80%;
  overflow-y: auto;
}

.card-cartao {
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #aac000;
  align-items: center;
  width: 100%;
  min-height: 115px;
  height: 100%;
}

.card-cartao-titulo {
  color: white;
  font-size: 18px;
  width: 35%;
  font-weight: 500;
  overflow-x: hidden;
  text-align: center;
}

.cartao-table-titulo span {
  overflow-x: hidden;
}

.card-cartao-subtitulo {
  color: white;
  font-size: 22px;
  text-align: end;
  width: 65%;
}
.card-cartao-footer {
  width: 100%;
  background-color: white;
  height: 1px;
  color: white;
}

@media screen and (max-width: 991px) {
  .card-cartao {
    font-size: 22px;
    display: flex;
    border-radius: 4px;
    height: 100px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: #aac000;
    align-items: center;
    width: 100%;
    min-height: 252px;
    height: 100%;
  }

  .card-cartao-titulo {
    color: white;
    font-size: 22px;
    width: 35%;
    font-weight: 500;
    overflow-x: hidden;
    text-align: center;
  }

  .card-cartao-subtitulo {
    color: white;
    font-size: 50px;
    text-align: end;
    width: 65%;
  }
  .card-cartao-footer {
    width: 100%;
    background-color: white;
    height: 1px;
    color: white;
  }
}

.f-24 {
  font-size: 24px;
}

.card-sem-cartao {
  font-size: 18px;
  display: flex;
  border-radius: 4px;
  height: 100px;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #aac000;
  align-items: center;
  width: 100%;
  min-height: 115px;
  height: 100%;
  justify-content: center;
  text-align: center;
  color: white;
}

.card-sem-cartao-footer {
  width: 100%;
  background-color: white;
  height: 1px;
  color: white;
}

@media screen and (max-width: 991px) {
  .card-sem-cartao {
    font-size: 22px;
    display: flex;
    border-radius: 4px;
    height: 100px;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
    background: #aac000;
    align-items: center;
    width: 100%;
    min-height: 252px;
    height: 100%;
    justify-content: center;
    color: white;
    text-align: center;
  }

  .card-sem-cartao-footer {
    width: 100%;
    background-color: white;
    height: 1px;
    color: white;
  }
}

.cartao-info-verde {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #1e7e34;
}

.cartao-info-vermelho {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #ff0000;
}

.cartao-info-preto {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #000000;
}

.cartao-info-marrom {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  height: 115px;
  width: 100%;
  background-color: #86724c;
}

.card-infos {
  display: flex;
  flex-direction: column;
  height: 130px;
  margin: auto;
  margin-top: 10px;
}

.card-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.card-links a {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.card-links a {
  color: #aac000;
}

.card-links a:hover {
  color: #aac000;
}

.card-imagem {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-imagem img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.card-imagem video {
  width: 100%;
}

.card-midia {
  background-color: white;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.card-midia-titulo {
  font-weight: bold;
}

:root {
  --bg-default: #363E31;
  --cor-primaria: #6CBE12;
  --font-size-default: 18px;
}

#modal-container {
  padding: 30px 15px;
  max-height: 80%;
  height: 100%;
  border: none;
  background: white;
  text-align: center;
}

#modal-container h3 {
  color: #6CBE12;
  color: var(--cor-primaria);
  font-size: 22px;
  font-weight: 800;
  margin: 0 0 12px 0;
}

#modal-container p {
  color: #363E31;
  color: var(--bg-default);
  font-size: 18px;
  font-weight: 400;
  margin: 0 0 10px 0;
}

#modal-container #endCep {
  display: block;
  margin: 0 auto 20px auto;
  color: #444;
  width: 80%;
  border-radius: 10px;
  border: 1px solid #6CBE12;
  border: 1px solid var(--cor-primaria);
  height: 45px;
  font-size: 18px;
  font-size: var(--font-size-default);
  text-align: center;
}

#modal-container #endCep::-webkit-input-placeholder {
  color: #444;
  text-align: center;
}

#modal-container #endCep:-ms-input-placeholder {
  color: #444;
  text-align: center;
}

#modal-container #endCep::placeholder {
  color: #444;
  text-align: center;
}

#modal-container #endCep:focus {
  text-align: center;
}


#modal-container #endCep:active {
  text-align: center;
}

#modal-container button {
  width: 50%;
  height: 45px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  font-size: var(--font-size-default);
  font-weight: 700;
  background: #6CBE12;
  background: var(--cor-primaria);
  color: white;
}

#modal-container button:hover {
  background: #ddd;
  color: #6CBE12;
  color: var(--cor-primaria)
}

#modal-container button:disabled {
  color: white;
  background: #ddd
}

.h-container {
  height: 85.77% !important;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*:focus {
  outline: 0 !important;
}

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialased;
}

body,
input,
button {
  font: 1rem 'Roboto', sans-serif;
}

button {
  cursor: pointer;
}
.a-clean,
.a-clean:hover,
.a-clean:active {
  color: inherit;
  text-decoration: inherit;
}

.Toastify__toast--success {
  background-color: #aac000;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--error {
  background-color: #aac000;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--info {
  background-color: #aac000;
  border-radius: 4px;
  text-align: center;
}
.Toastify__toast--warning {
  background-color: #aac000;
  border-radius: 4px;
  text-align: center;
}

.f-16 {
  font-size: 16px;
}

.f-14 {
  font-size: 14px;
}

.bg-black {
  background-color: black !important;
}

.f-black {
  color: black;
}

.f-white {
  color: white;
}

.f-blue {
  color: blue;
}

.btn-primario {
  margin: 5px 0 0;
  height: 44px;
  background-color: #aac000;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}
/*
.btn-primario:hover {
  background-color: white;
  color: #aac000;
  border: 1px solid #aac000;
} */

.btn-secundario {
  margin: 5px 0 0;
  height: 44px;
  background-color: black;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
}

.btn-secundario:hover {
  background-color: #a6b438;
}

.f-12 {
  font-size: 12px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-white {
  color: white;
}

.f-green {
  color: green;
}

.f-red {
  color: red;
}

.hr-loja, .hr-loja-desktop {
  width: 100%;
  border-top: 3px solid #aac000;
}

ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
}

ol > li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
}

ol > li:before {
  content: counters(item, '.') '. ';
  display: table-cell;
  padding-right: 0.6em;
}

li ol > li {
  margin: 0;
}

li ol > li:before {
  content: counters(item, '.') ' ';
}

.row:before,
.row:after {
  display: none !important;
}

.caret-4-desc:after {
  content: '\2193';
}
.caret-4-desc:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

.caret-4-asc:after {
  content: '\2193';
  opacity: 0.4;
}
.caret-4-asc:before {
  margin-left: 3.5px;
  content: '\2191';
}

ul.pagination.react-bootstrap-table-page-btns-ul {
  margin-top: 1rem;
}

.btn-limpo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

div.ril-toolbar.ril__toolbar {
  background-color: unset;
}

.table-dark thead th {
  color: white;
}

.table-dark td {
  background-color: white;
  color: black;
  border-color: #e4e7e9 !important;
}


.table-dark td:focus {
  outline: none !important;
  border: 10px solid black;
}

.table-dark tfoot {
  background-color: white;
  color: black;
}

.table-dark {
  white-space: nowrap;
  font-size: 14px;
  background-color: #777777 !important;
}

.barra-busca {
  float: right !important;
  border: none;
  outline: 0 !important;
  width: 150px !important;
  color: black;
}

.textarea {
  padding: 10px !important;
  min-height: 100px;
}

.input-select {
  width: auto;
  height: 35px;
  border: 1px solid #aac000;
  border-radius: 4px;
  background-color: white;
}

.input-theme {
  height: 35px !important;
  border: 1px solid #aac000 !important;
  border-radius: 4px !important;
  background-color: white !important;
  outline: none !important;
  color: black !important;
  box-shadow: none !important;
}

.input-theme::-webkit-input-placeholder {
  color: black !important;
}

.input-theme:-ms-input-placeholder {
  color: black !important;
}

.input-theme::placeholder {
  color: black !important;
}

input,
select {
  padding-left: 16px !important;
}

.react-select__placeholder {
  padding-left: 15px;
}

.btn-busca {
  height: 35px;
  width: 35px;
  background-color: #aac000;
  font-weight: bold;
  color: white;
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 25px;
}

@media screen and (min-width: 576px) {
  .btn-busca {
    height: 35px;
    width: 35px;
    background-color: #aac000;
    font-weight: bold;
    color: white;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
  }
}

.search-label {
  max-width: 250px;
}

@media screen and (max-width: 768px) {
  .search-label {
    width: 100% !important;
    max-width: 100%;
  }
}

.btn-primario-slim {
  height: 35px;
  background-color: #aac000;
  font-weight: bold;
  color: rgb(255, 255, 255);
  border: 0;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 576px) {
  .btn-primario-slim {
    height: 35px;
    background-color: #aac000;
    font-weight: bold;
    color: rgb(255, 255, 255);
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 25px;
    padding-left: 5px;
    padding-right: 5px;
    margin: 0;
  }
}

.alert-antifraude,
.alert-antifraude:hover,
.alert-antifraude:visited {
  font-size: 14px;
  margin-left: 5px;
  color: red !important;
  text-decoration: none;
}

.link-clean,
.link-clean:hover,
.link-clean:visited {
  color: black;
}

.form-control.editor.edit-text:focus {
  box-shadow: none;
  border: 1px solid #aac000;
}

.premio-desc {
  min-height: 100px;
}

.modal-footer .btn-primary {
  background-color: #aac000 !important;
  border-color: #aac000 !important;
  color: white !important;
}

.modal-footer .btn-primary:hover {
  background-color: #95a800 !important;
  border-color: #95a800 !important;
}

.modal-footer .btn-secondary {
  background-color: #777777 !important;
  border-color: #777777 !important;
  color: white !important;
}

.modal-footer .btn-secondary:hover {
  background-color: #666666 !important;
  border-color: #666666 !important;
}

.modal-header {
  border-bottom: 3px solid #aac000 !important;
}

.modal-body .form-control:focus {
  border-color: #aac000 !important;
  box-shadow: 0 0 0 0.2rem rgba(170, 192, 0, 0.25) !important;
}

.modal-body .text-primary {
  color: #aac000 !important;
}

.modal-body .text-primary:hover {
  color: #95a800 !important;
}

.iiz {
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: zoom-in;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
}

.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: zoom-out;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.iiz__btn:before {
  content: ' ';
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: ' ';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
}

.iiz__close::before {
  content: ' ';
  width: 29px;
  height: 29px;
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  transform: rotate(45deg);
}

